export default {
    name: "Technology",
    data() {
        return {
            window: window,
            dataList: [{
                    videoUrl: require("../../assets/video1.mp4"),
                    title: "加密即插即种技术",
                    text: "结合亚洲人的毛发特点和脱发特征，对取发器械和种植器械进行改良升级，器械口径为0.6-0.9mm，可根据毛干直径进行灵活调整，达到创伤小、恢复快、术后近乎无痕的效果，并采用即插即种的方式，实现毛囊存活率和毛发自然度、美观度的提升。",
                    loop: 1,
                },
                {
                    videoUrl: require("../../assets/video1.mp4"),
                    title: "精密即插即种技术",
                    text: "严格按照美学标准，定制专人专项的植发手术方案，采用精密级进口一次性器械，根据实际毛囊生长情况和美学设计需求选择合适的提取既种植口径，对精度、深度、密度、角度的运用掌控更为精湛，最后植发效果所呈现的自然度也更为完美。",
                    loop: 1,
                },
                {
                    videoUrl: require("../../assets/video1.mp4"),
                    title: "不剃发植发技术",
                    text: "该技术以长发移植为特点，无需将后枕部供体区域的头发剃短，在保留原生发长度的基础上进行提取，并直接种植在脱发区域，达到无痕提取、长发种植、效果即刻呈现的效果，避免了剃发和术后等待期等环节。且术后无需包扎，第二天即可洗头，清爽度、舒适度更高，非常适合短期内需要出席重要场合或者急需植发的发友群体。",
                    loop: 1,
                },
            ],
            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],
        };
    },
    methods: {
        play(index) {
            console.log(index);
            if (this.dataList[index].loop == 1) {
                this.dataList[index].loop == 2;
            } else if (this.dataList[index].loop == 2) {
                this.dataList[index].loop == 3;
            } else if (this.dataList[index].loop == 3) {
                this.dataList[index].loop == 2;
            }
        },
    },
};