var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Technology",style:({ height: _vm.window.screen.height + 'px' })},[_vm._m(0),_c('div',{staticClass:"boxs"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,style:({
        background:
          index == 0
            ? '#3B659E'
            : index == 1
            ? '#597AB2'
            : index == 2
            ? '#6B8CC4'
            : '',
      })},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"box",class:[
            index == 0
              ? 'transparency1'
              : index == 1
              ? 'transparency2'
              : index == 2
              ? 'transparency3'
              : '' ]},[_c('div',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.play(index)}}},[_c('div',{staticClass:"video"},[_c('video',{staticClass:"video",attrs:{"src":item.videoUrl,"controls":"controls","loop":"loop","poster":require("../../assets/start-mask.jpg"),"preload":"preload"}})]),_c('div',{staticClass:"textBox"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])])])])])])}),0),_c('div',{staticClass:"foot"},_vm._l((_vm.bottomList),function(item,index){return _c('div',{key:index,staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"1.25rem","height":"1.25rem"},attrs:{"alt":"Vue logo","src":item.url}}),_c('br'),_c('div',{staticClass:"textStyle"},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"8.4vh","background":"rgb(52, 126, 240)","display":"flex"}},[_c('div',{staticStyle:{"height":"28px","border":"0.1px solid rgb(255, 255, 255)","position":"absolute","left":"8rem","top":"1.1rem","opacity":"0.6"}}),_c('div',{staticClass:"navText"},[_vm._v("植发技术")])])}]

export { render, staticRenderFns }